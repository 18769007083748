var browser, os, WW, WH, DH, ST, baseURL, lazy, minW = 320, resizeTo, lang, doc = 'document,html,body', base = ($('base').length ? $('base').attr('href') : $('html').data('base')), lang = $('html').attr('lang'), d = false, previousTop = 0, opened = 0, runing, bd = $('.backdrop'), navbarHover = 0, lastScrollTop = '', justEmpty = '', scrolldown = 0, basketPageAppend = 0, running = false, triggered = 0, targetYTID = '';
browser = getBrowser();
os = getOS();

$(document).ready(function () {
    lang = $('html').attr('lang');
    baseURL = $('base').attr('href');
    $('html').addClass('br_' + browser).addClass('os_' + os);

    resize();
    $(window).resize(function () {
        clearTimeout(resizeTo);
        resizeTo = setTimeout(function () {
            resize();
        }, 400);
    });

    scroll();
    $(window).scroll(function () {
        scroll();
    });

    if ($('.information').length) {
        $('.information').click(function() {
            var target = $(this).data('target');

            $(target).find('.modal-dialog').css({
                'margin-left': ($(this).offset().left - 10)
            });
        });
    }
});

function scroll() {
    WW = $(window).width();
    ST = $(window).scrollTop();

    /* create scroll functions */
    if (ST < previousTop) {
        //ha fel görgetek

        if ((ST + 40) <= lastScrollTop) {
            $('html').removeClass('scrolled-down');
        }

        if (ST > 0) {
            //ha nem a tetején vagyok
        } else {
            //ha a tetjére ért
        }


    } else {
        var cat = $('header').height();

        if (ST > previousTop && ST > cat && !d) {
            //ha le görgetek
            $('html').addClass('scrolled-down');

            lastScrollTop = ST;

            if (scrolldown == 0) {
                setTimeout(function () {
                    $(window).scrollTop($(window).scrollTop() + 1);
                    scrolldown = 1;
                }, 100);
            }
        }
    }

    previousTop = ST;
}

function resize() {
    WW = $(window).width();
    WH = $(window).height();
    DH = $(document).height();

    /* create resize functions */
    if (WW >= 768) {
        /* Ha nagyobb az oldal, mint 991px */

        if ($('.page-threecol').length) {
            resizeBoxes('.col-item .col-content');
        }
    } else {
        /* Ha kisebb az oldal, mint 992px */

        if ($('.page-threecol').length) {
            $('.col-item .col-content').removeAttr('style');
        }
    }
}
