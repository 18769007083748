function RemoveAccents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽžőű';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZzou";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
}

function resizeBoxes(boxes) {
    if ($.type(boxes) === 'string') boxes = $(boxes);

    var h = 0;
    boxes.height('auto');
    boxes.each(function () {
        if ($(this).height() > h) h = $(this).height();
    });
    boxes.height(h);
}

function getOS() {
    var a = '';
    var osString = {
        'windows': /(Win)/,
        'android': /Android/,
        'linux': /(Linux|X11)/,
        'ios': /(iPhone|iPad|iPod|Mac)/,
        'unix': /UNIX/,
        'bot': /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    };

    $.each(osString, function (k, v) {
        if (a == '' && v.test(navigator.userAgent)) a = k;
    });
    return a;
}

function getBrowser() {
    var a = '';
    var browserString = {
        'Chrome': 'chrome',
        'MSIE': 'ie',
        'Trident/': 'ie',
        'Firefox': 'ff',
        'Safari': 'safari',
        'Presto': 'opera',
        'Opera': 'opera'
    };
    $.each(browserString, function (k, v) {
        if (a == '' && navigator.userAgent.indexOf(k) != '-1') a = v;
    });
    return a;

}

/* apple-safari mobile rotation bug fixed */
if (os == 'ios' && browser == 'safari') {
    (function (doc) {
        var addEvent = 'addEventListener',
            type = 'gesturestart',
            qsa = 'querySelectorAll',
            scales = [1, 1],
            meta = qsa in doc ? doc[qsa]('meta[name=viewport]') : [];

        function fix() {
            meta.content = 'width=device-width,minimum-scale=' + scales[0] + ',maximum-scale=' + scales[1];
            doc.removeEventListener(type, fix, true);
        }

        if ((meta = meta[meta.length - 1]) && addEvent in doc) {
            fix();
            scales = [.25, 1.6];
            doc[addEvent](type, fix, true);
        }
    }(document));
}

/* windows phone 8.1 bug fixed */
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement("style");
    msViewportStyle.appendChild(
        document.createTextNode(
            "@-ms-viewport{width:auto!important}"
        )
    );
    document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
}